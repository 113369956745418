import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import logo from "../images/logo.svg"
import logoDark from "../images/logo-dark.webp"
import crossIcon from "../images/cross.svg"
import hamburgerIcon from "../images/hamburger-icon.svg"
import Button from "../components/button"
import Navi from "../components/navi"
const HeaderLink = (props) => {
  const { label, path, isMenuHidden, ...restProps } = props
  return (
    <Link
      {...restProps}
      className={
        typeof isMenuHidden === "undefined"
          ? "header-link"
          : isMenuHidden
          ? "header-link"
          : "header-link-shown"
      }
      to={path}
    >
      {label}
    </Link>
  )
}
const schoolplds = [
  {
    label: "Professional Learning & Development",
    slug: "professional-learning-development",
  },
  {
    label: "Careers & Gateway Programmes",
    slug: "careers-gateway-programmes",
  }
]
const university = [
  {
    label: "Bachelor of Arts in Intercultural Studies",
    slug: "bachelor-of-arts-in-intercultural-studie",
  },
  {
    label: "Bachelor of Arts in Christian Education",
    slug: "bachelor-of-arts-in-christian-education",
  },
  {
    label: "Bachelor of Arts",
    slug: "bachelor-of-arts",
  },
  {
    label: "Accreditation",
    slug: "accreditation",
  }
]



const infos = [
  {
    label: "International students",
    slug: "#international_students",
  },
  {
    label: "Refund policy",
    slug: "#refund_policy",
  },
  {
    label: "Pastoral care",
    slug: "#pastoral_care",
  },
  {
    label: "Educational performance",
    slug: "#educational_performance",
  },
  {
    label: "Homestay",
    slug: "homestay",
  },
  {
    label: "How you can study",
    slug: "../study",
  },
  {
    label: "Summit Institute GDPR Privacy Policy",
    slug: "summit-institute",
  },
]

const Header = (props) => {
  const subjectLinks = props.subjectLinks
  const [isMenuHidden, setMenuHidden] = useState(true)
  useEffect(() => {
    const setMenuHiddenForDesktop = () => {
      if (window.innerWidth >= 1024) {
        setMenuHidden(true)
      }
    }

    window.addEventListener("resize", setMenuHiddenForDesktop)
    return (_) => window.removeEventListener("resize", setMenuHiddenForDesktop)
  })

  useEffect(() => {
    if (isMenuHidden) {
      document.body.style.position = ""
    }
  })

  return (
    <div className="header-bottom-line">
      <div>
        <div
          className={`layout-container header-container ${
            !isMenuHidden ? "menu-container" : ""
          }`}
        >
          {!isMenuHidden && (
            <button
              onClick={(e) => {
                document.body.style.position = ""
                setMenuHidden(true)
              }}
            >
              <img src={crossIcon} className="cross-icon" alt="close menu" />
            </button>
          )}
          {isMenuHidden && (
            <button
              onClick={() => {
                document.body.style.position = "fixed"
                setMenuHidden(false)
              }}
            >
              <img
                src={hamburgerIcon}
                alt="hamburger icon"
                className="hamburger-icon"
              />
            </button>
          )}
          <Link
            to="/"
            className={
              isMenuHidden ? "header-logo-wrapper" : "header-logo-wrapper-shown"
            }
          >
            <img src={isMenuHidden ? logo : logoDark} alt="logo" style={{ width: '161px' }} />
          </Link>
          <Navi
            label="School PLD & Gateway"
            path="#"
            prefix="school-pld-gateway"
            subLinks={schoolplds}
            isMenuHidden={isMenuHidden}
          ></Navi>
          <Navi
            label="Subjects"
            path="/subjects"
            prefix="subject"
            subLinks={subjectLinks}
            isMenuHidden={isMenuHidden}
          ></Navi>
         
          <Navi
            label="Important Info"
            path="/info"
            prefix="info"
            subLinks={infos}
            isMenuHidden={isMenuHidden}
          ></Navi>
          <Navi
            label="Global University"
            path="#"
            prefix="global-university"
            subLinks={university}
            isMenuHidden={isMenuHidden}
          ></Navi>
          <a className={
              isMenuHidden ? "header-link" : "header-link-shown"
            } href="/blogs">Blogs</a>
          <HeaderLink
            label="Contact"
            path="/contact"
            isMenuHidden={isMenuHidden}
          ></HeaderLink>
          <div
            className={
              isMenuHidden ? "login-enrol-wrapper" : "login-enrol-wrapper-shown"
            }
          >
            {isMenuHidden ? (
              <HeaderLink
                label="Log in"
                path="https://live.teamsplus.app/login/ed0e0f7e-efa4-4f88-8f9f-c2806969cc1b"
              ></HeaderLink>
            ) : (
              <a
                href="https://live.teamsplus.app/login/ed0e0f7e-efa4-4f88-8f9f-c2806969cc1b"
                style={{ width: `100%` }}
              >
                <Button colorSchema="secOutline" size="spl">
                  Log in
                </Button>
              </a>
            )}
            {!isMenuHidden && <div className="gap"></div>}
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf3lEQ9MHRJFhrPFUfEqL4qxL_UrO28QJlnYjb27V2I1lYcvQ/viewform" target="_blank" class="btn-nl btn-sec enroll-button" style={{textDecoration:`none`}}>Enrol</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
